export default {
    state: {
        tagList: [],
        cachedViews: ["property", "lease", "settleearnings","exceptional","reviewLease"], // 缓存组件列表
        isAdmin: false
    },
    actions: {
    },
    mutations: {
        ChangeTags(state, value) {
            // console.log(state.tagList, value);
            //如果重复不再添加新的进去
            if (value.url != '') {
                const result = state.tagList.findIndex(item => item.url === value.url)
                result === -1 ? state.tagList.push(value) : ''
            }



            var reg = RegExp(/carinfo/); //检索是否含有'carinfo'
            if (value.url.match(reg) || value.url == "/settleearnings") {
                const res = state.cachedViews.findIndex(item => item === value.url.replace('/', ''))
                res === -1 ? state.cachedViews.push(value.url.replace('/', '')) : ''
            }

        },
        //删除标签
        closeTab(state, val) {
            const result = state.tagList.findIndex(item => item.url === val.url)
            state.tagList.splice(result, 1)

            var reg = RegExp(/carinfo/); //检索是否含有'carinfo'
            if (val.url.match(reg) || val.url == "/settleearnings") {
                const res = state.cachedViews.findIndex(item => item === val.url.replace('/', ''))
                state.cachedViews.splice(res, 1)
            }

        },

        //重置reset
        reset(state, val) {
            console.log();
            state.tagList = [
                { name: "资产管理", url: "/property" }
            ]
        },

        //清空taglist
        clearTaglist(state) {
            state.tagList = []
        },

        //判断用户类型
        changeUserType(state, val) {
            //模拟数据判断
            if (val) {
                state.isAdmin = true
            } else {
                state.isAdmin = false

            }
        }
    }
}