import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// 引入nprogress 进度条插件
import NProgress from 'nprogress'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由懒加载  import(/* webpackChunkName: "property" */ '../components/property.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/', redirect: '/login'
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login_home" */ '../components/Login.vue')
  },
  {
    path: '/home',
    redirect: to => {
      const result = JSON.parse(
        window.sessionStorage.getItem('userInfo'))[0].userInfo.hasAdmin

      if (result) {
        return '/user'
      } else {
        return '/property'
      }
    },
    // redirect: '/property',
    component: () => import(/* webpackChunkName: "login_home" */ '../components/Home.vue'),
    children: [
      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ '../components/user.vue'),
        meta: {
          type: 'admin'
        }
      },
      {
        path: '/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ '../components/role.vue'),
        meta: {
          type: 'admin'
        }
      },
      {
        path: '/permit',
        name: 'permit',
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ '../components/permit.vue'),
        meta: {
          type: 'admin'
        }
      },
      {
        path: '/property',
        name: 'property',
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ '../components/property.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/lease',
        name: 'lease',
        component: () => import(/* webpackChunkName: "lease" */ '../components/lease.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/reviewLease',
        name: 'reviewLease',
        component: () => import(/* webpackChunkName: "reviewLease" */ '../components/reviewLease.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/provePayment',
        name: 'provePayment',
        component: () => import(/* webpackChunkName: "provePayment" */ '../components/provePayment.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/renderResource',
        name: 'renderResource',
        component: () => import(/* webpackChunkName: "renderResource" */ '../components/renderResource.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/stockreport',
        component: () => import(/* webpackChunkName: "stockreport" */ '../components/stockreport.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/addcarinfo',
        name: 'addcarinfo',
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ '../components/AddCarInfo.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/editcarinfo',
        name: 'editcarinfo',
        component: () => import(/* webpackChunkName: "property_addcarinfo_editInfo" */ '../components/editcarinfo.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/settlereport',
        name: 'settlereport',
        component: () => import(/* webpackChunkName: "report" */ '../components/settlereport.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/propertypercent',
        name: 'propertypercent',
        component: () => import(/* webpackChunkName: "report" */ '../components/propertypercent.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/settleearnings',
        name: 'settleearnings',
        component: () => import(/* webpackChunkName: "report" */ '../components/settleearnings.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/exceptional',
        name: 'exceptional',
        component: () => import(/* webpackChunkName: "exceptional" */ '../components/exceptionalProperty.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/customizedbill',
        name: 'customizedbill',
        component: () => import(/* webpackChunkName: "bill" */ '../components/customizedBill.vue'),
        meta: {
          type: 'user'
        }
      },
      {
        path: '/billrecord',
        name: 'billrecord',
        component: () => import(/* webpackChunkName: "bill" */ '../components/billRecord.vue'),
        meta: {
          type: 'user'
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '../components/404.vue'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }

]

const router = new VueRouter({
  routes
})

// 路由导航守卫控制---访问权限
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start()

  //   //to将要访问的路径
  //   //from从那个路径跳转而来
  //   //next函数 执行下一步 next()或next('/login)
  //   console.log(to);
  if (to.path === '/login') return next()

  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) {
    return next('/login')
  } else {
    // 用户权限守卫
    const result = JSON.parse(
      window.sessionStorage.getItem('userInfo')
    )[0].userInfo.hasAdmin
    if (!result && to.meta.type == 'admin') {
      // console.log('无权限');
      alert('您无权限访问该页面')
      next(from.path)
      return
    }
  }

  // if (to.matched.length === 0) return next('/404');
  console.log(from)

  // console.log(store.state.tab.tagList);
  const result = store.state.tab.tagList.findIndex(item => item.url === to.path)
  // if (result == -1) {
  //   console.log('不存在');

  // } else {
  //   console.log('存在');
  // }

  const MenuItem = {
    name: '',
    url: ''
  }
  const activePath = to.path
  if (activePath == '/property') {
    (MenuItem.name = '资产管理'), (MenuItem.url = activePath)
  } else if (activePath == '/lease') {
    (MenuItem.name = '租赁合同管理'), (MenuItem.url = activePath)
  } else if (activePath == '/stockreport') {
    (MenuItem.name = '存量报表'), (MenuItem.url = activePath)
  } else if (activePath == '/addcarinfo') {
    (MenuItem.name = '新增资产管理'), (MenuItem.url = activePath)
  } else if (activePath == '/editcarinfo') {
    (MenuItem.name = '编辑资产管理'), (MenuItem.url = activePath)
  } else if (activePath == '/user') {
    (MenuItem.name = '用户管理'), (MenuItem.url = activePath)
  } else if (activePath == '/role') {
    (MenuItem.name = '角色管理'), (MenuItem.url = activePath)
  } else if (activePath == '/permit') {
    (MenuItem.name = '权限管理'), (MenuItem.url = activePath)
  } else if (activePath == '/settlereport') {
    (MenuItem.name = '结清报表'), (MenuItem.url = activePath)
  } else if (activePath == '/propertypercent') {
    (MenuItem.name = '资产占比'), (MenuItem.url = activePath)
  } else if (activePath == '/settleearnings') {
    (MenuItem.name = '结清收益'), (MenuItem.url = activePath)
  } else if (activePath == '/exceptional') {
    (MenuItem.name = '异常资产'), (MenuItem.url = activePath)
  } else if (activePath == '/customizedbill') {
    (MenuItem.name = '定制账单'), (MenuItem.url = activePath)
  } else if (activePath == '/billrecord') {
    (MenuItem.name = '账单管理'), (MenuItem.url = activePath)
  } else if (activePath == '/reviewLease') {
    (MenuItem.name = '合同审核管理'), (MenuItem.url = activePath)
  } else if (activePath == '/provePayment') {
    (MenuItem.name = '核销管理'), (MenuItem.url = activePath)
  } else if (activePath == '/renderResource') {
    (MenuItem.name = '进件管理'), (MenuItem.url = activePath)
  }
  if (to.path != '' && activePath != '/login' && activePath != '/home') store.commit('ChangeTags', MenuItem)

  // 守卫itcarinfo页面
  if (to.path == '/editcarinfo' && to.params.assetsId == undefined && result == -1) {
    console.log('非进入编辑页面')
    store.commit('closeTab', MenuItem)
    return next('/property')
  }

  next()
})

router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})
export default router
